<template>
  <div>
    <div class="back">
      <img :src="domainData.logoUrl" class="logo">
      <img src="../../assets/img/loginbox.png" style="height: 76%;margin-top: 5%;">
      <img src="../../assets/img/line.png" class="line">
      <div class="loginform" v-loading="loading">
        <div class="titile"><span>{{domainData.sysName}}</span></div>
        <hr style="width: 23%;margin-top: 25px;margin-bottom: 25px;border-color: #acdaff3d;">
        <div>
          <el-input v-model="ruleForm.name" placeholder="请输入账号"  class="w-50" maxlength="25"></el-input>
        </div>
        <div>
          <el-input v-model="ruleForm.password" type="password" show-password placeholder="请输入密码"  class="w-50" maxlength="20"></el-input>
        </div>
        <div style="display: flex;justify-content: center;" v-show="codeVisible">
          <el-input v-model="ruleForm.code" placeholder="请输入验证码" class="w-20"></el-input>
          <div class="w-20 code" @click="getCode()">{{newcode}}</div>
        </div>
        <div>
          <el-button type="primary" round class="loginbtn" @click="login()">登录</el-button>
        </div>
      </div>
    </div>

    <div class="login-dialog">
      <el-dialog v-model="dialogFormVisible" title="请选择登录机构" width="50%" >
        <div v-show="dialogLoading" v-loading="dialogLoading"></div>
        <template v-for="(item,index) in unitList" :key="index">
          <div class="unitbox" @click="loginto(item)" >
              <span>{{item.sysName}} </span>
              <span>{{item.unitName}}</span>
              <span v-if="item.accountType == 1">(A级)</span>
              <span v-else-if="item.accountType == 2">(B级)</span>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import { ElMessage } from 'element-plus'
import { getLoginLogoName, sysLogin,getCustomerInfo } from '../../api/api'
import session from '../../store/store'
import crypto from '../../until/crypto'
import until from '../../until/until'
export default {
  data() {
    return {
      domainData: {
        logoUrl: "",
        sysName: ""
      },
      ruleForm: {
        name: "",
        password: "",
        code: ""
      },
      newcode: '',
      dialogFormVisible: false,
      codeVisible: false,
      unitList: [],//机构集合
      customUuid: '',
      loading:false,
      dialogLoading:false,
    }
  },
  mounted() {
    this.getCode();//获取验证码
    this.getLogoAPI();//获取logo信息
  },
  methods: {
    //获取验证码start--------------------------------------------------------------------------------------
    rnd(start, end) {
      return Math.floor(Math.random() * (end - start + 1)) + start;
    },
    getCode() {
      var arr = [];
      var len = 4;
      for (var i = 0; i < len; i++) {
        var num = this.rnd(48, 122); // 58
        if ((num >= 48 && num <= 57) || (num >= 65 && num <= 90) || (num >= 97 && num <= 122)) {
          arr[i] = String.fromCharCode(num);
        } else {
          // 如果不是有效ascii码，则增加一次循环
          len++;
        }
      }
      this.newcode = arr.join("");
    },
    //获取验证码end--------------------------------------------------------------------------------------

    //点击登陆按钮----------------------------------------------------------------------------------------
    login() {
      if (this.ruleForm.name == '') {
        this.$message({ message: '请输入账号！',  type: 'warning'});
        return;
      }
      if (this.ruleForm.password == '') {
        this.$message({ message: '请输入密码！',  type: 'warning'});
        return;
      }
      if(this.codeVisible) {
        if (this.ruleForm.code == '') {
          this.$message({ message: '请输入验证码！',  type: 'warning'});
          return;
        }
        if (this.ruleForm.code != this.newcode) {
          this.$message({ message: '验证码错误！',  type: 'warning'});
          return;
        }
      }

      this.loginAPI();
    },
    /**
     * 登录接口
     */
    loginAPI() {
      this.loading = true;
      var param = {
        url: window.location.host,
        login: crypto.Encrypt(this.ruleForm.name),
        password: crypto.Encrypt(this.ruleForm.password),
      }
      sysLogin(param).then(res => {
        this.loading = false;
        if (res.data.code == 200) {
          this.unitList = res.data.dataInfo.loginList;
          this.customUuid = res.data.dataInfo.customUuid;
          this.codeVisible = false;

          if(1 == res.data.dataInfo.loginNum) {
            this.dialogFormVisible = false;
            this.loginto(res.data.dataInfo.loginList[0]);
          }else {
            this.dialogFormVisible = true;
          }
        } else {
          this.$message.error({ message: res.data.message});
          this.codeVisible = true;
        } 
      })
    },
    /**
     * 点击机构跳转首页
     */
    loginto(item) {
      if(1 == item.loginDeniedFlag) {
        this.$message.error({ message: item.DeniedDescribe});
        return;
      }
      session.setLoginInfo(item);
      session.setCustomerUuid(this.customUuid);
      session.setToken(this.newcode);
      this.dialogLoading = true;
      this.getCustomerInfo(item);
    },
    getCustomerInfo(item) {
      var param = {
        uuid: session.getLoginInfo().unitUuid,
      }
      getCustomerInfo(param).then(res => {
        
        if (res.data.code == 200) {
          this.setSysInfo(res.data.dataInfo);
          if(1 == item.accountType) {//A级
            this.$router.push('/index')
          }else {
            this.$router.push('/index')
          }
          this.dialogLoading = false;
        } 
      })
    },
    setSysInfo(data) {
      var sysLogoUrl = data.sysLogoUrl;
      var sysName = data.sysDisplayData.sysName;
      if (until.isNULL(sysLogoUrl)) {
        sysLogoUrl = require('../../assets/img/ls.png');
      }
      session.setSysName(sysName);
      session.setSysLogoUrl(sysLogoUrl);
    },
    /**
     * 获取logo信息API
     */
    getLogoAPI() {
      var param = {
        url: window.location.host,
      }
      getLoginLogoName(param).then(res => {
        if (res.data.code == 200) {
          if (until.isNULL(res.data.dataInfo.loginSysLogoUrl)) {
            this.domainData.logoUrl = require('../../assets/img/logo.png');
          }else {
            this.domainData.logoUrl = res.data.dataInfo.loginSysLogoUrl;
          }
          
          this.domainData.sysName = res.data.dataInfo.loginSysName;

          document.title =  this.domainData.sysName
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.back {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/img/loginback.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  text-align: center;
}
.logo {
  position: absolute;
  top: 14%;
  margin-left: 28px;
  width: 250px;
  height: 120px;
}
.line {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 42%;
}
.loginform {
  position: absolute;
  bottom: 23%;
  right: 21%;
  width: 27%;
  height: 60%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 4px 4px 12px -2px var(--el-svg-monochrome-grey);
}
.titile {
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  span {
    font-size: 32px;
    color: #0757db;
    font-family: emoji;
    font-weight: 600;
  }
}
.w-50 {
  width: 55%;
  margin: 3% 0px;
}
.w-20 {
  width: 25%;
  margin: 3% 11px;
}
.loginbtn {
  width: 54%;
  background: #0757db;
  margin: 10% 0px;
  font-size: 16px;
  font-weight: 600;
}
.code {
  border: 1px solid var(--el-border-color-light);
  padding: 9px 0px;
  border-radius: 4px;
  color: #0757db;
}
.unitbox {
  margin: 5px 0px;
  padding: 15px;
  border: 1px solid #909399;
  border-radius: 8px;
  box-shadow: 3px 3px 5px 2px #90939933;
  cursor: pointer;
  span {
    margin: 0px 10px;
    color: #909399;
  }
}
.unitbox:hover {
  margin: 5px 0px;
  padding: 15px;
  border: 1px solid #2196f3;
  border-radius: 8px;
  box-shadow: 3px 3px 5px 2px #2196f32e;
  cursor: pointer;
  span {
    margin: 0px 10px;
    color: #2196f3;
  }
}
</style>
<style>

/* .el-dialog {
  width: 30% !important;
  border-radius: 10px !important;
} */
</style>